<template>
	<div>
		<!-- 在库列表 -->
		<div class="listHeader">
			<i></i><span>{{$t('ksaWhInventoryPage.productInWarehouseList')}}</span>
		</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
		  style="margin:10px 0 0 15px">
		  <el-form-item label prop="companyProductCode">
		    <el-input type="textarea" v-model="companyProductCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientProductCode')" clearable />
		  </el-form-item>
			<el-form-item label prop="productCode">
			  <el-input type="textarea" v-model="queryParams.productCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.productCode')" clearable />
			</el-form-item>
		  <el-form-item>
		    <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button type="primary" icon="el-icon-refresh" @click="getList">{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
		  </el-form-item>
		</el-form>
		
		<!-- 展示 -->
		<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="loading" :data="dataList" border>
			<el-table-column :label="$t('ksaWhInventoryPage.clientBatchCode')" align="center" min-width="150" prop="customerBatchNo"/>
		  <el-table-column :label="$t('commonInfo.productCode')" align="center" min-width="120" prop="productCode"/>
		  <el-table-column :label="$t('commonInfo.clientProductCode')" align="center" min-width="170" prop="companyProductCode" />
		  <el-table-column :label="$t('commonInfo.productName')" align="center" min-width="130" prop="productNm" />
			<el-table-column :label="$t('ksaWhInventoryPage.whName')" align="center" min-width="120" prop="warehouseName" />
			<el-table-column :label="$t('ksaWhInventoryPage.locationName')" align="center" min-width="120" prop="locationName" />
			<el-table-column :label="$t('ksaWhInventoryPage.inventoryAmount')" align="center" min-width="140" prop="count" />
			<el-table-column :label="$t('ksaWhInventoryPage.putOnTime')" align="center" min-width="170" prop="instockTime" />
		</el-table>
		<div class="pageBottom">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="queryParams.page"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="queryParams.limit"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		  ></el-pagination>
		</div>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	export default {
	  //import引入的组件需要注入到对象中才能使用
	  components: {
	  },
	  data() {
	    //这里存放数据
	    return {
				queryParams: {
					page:1,
					limit:10,
					companyProductCodeList:[],
					productCodeList:[],
				},
				companyProductCode:'',// 批量检索
				productCode:'',//批量检索
				loading:false,
				dataList:[],
				total:0,
				flag: false,
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
			  this.queryParams.limit = size;
			  this.queryParams.page = 1;
			  this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
			  this.queryParams.page = page;
			  this.getList();
			},
			async getList() {
				this.loading = true;
				this.dataList = [];
				const res = await this.$http.post("toborder/wms/product/getInInventoryInformation",this.queryParams);
				
				if (res.code === 200) {
				  this.loading = false;
				  this.dataList = res.data.list;
					this.total = res.data.totalCount;
				} else {
				  this.loading = false;
				  this.$message.error(res.msg);
				}
			},
			
			handleQuery(){
				if(this.companyProductCode != ''){
					this.queryParams.companyProductCodeList = this.strToArr(this.companyProductCode);
				}else{
					this.queryParams.companyProductCodeList = [];
				}
				if(this.productCode != ''){
					this.queryParams.productCodeList = this.strToArr(this.productCode);
				}else{
					this.queryParams.productCodeList = [];
				}
				this.queryParams.page = 1;
				this.getList();
				
			},
			handleRefresh(){
				this.companyProductCode = '';
				this.productCode = '';
				this.queryParams = {
					page:1,
					limit:10,
					companyProductCodeList:[],
					productCode:[],
				}
				this.getList();
			}
		},
		created() {
			this.getList();
		}
	}
</script>

<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px 0 0 50px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>